// External Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Internal Libs
import Modal from '../../../components/modal/modal';
import { removeIreqIdInitialNumber, isActiveAddressById, isValidMarkShippedDate, isDateOnOrBeforeToday, convertDate } from '../../../common/functions';
import { getPDFNameTemplate, getShippingAddress, getShippingAddressName, onReloadPage } from '../../../common/helpers';
import './shipping_container.scss';

import { UserPermissionsContext } from '../../../context/user_permission';
import { userHasPermission } from '../../../common/permission';

// Redux Actions
import {
  toggleModal,
  onIrApproveShippingButtonClick,
  onIrMarkShippedButtonClick,
  closeIrApproveShippingModal,
  openIrMarkShippedModal,
  closeIrMarkShippedModal,
  openShippingLabelModal,
  closeShippingLabelModal,
  openInternationalModal,
  closeInternationalModal
} from '../../../redux/actions/bpp/shipping/shipping';

// Redux Reducers
import {
  getIrApproveShippingModal,
  getIrApproveShippingPending,
  getIrApproveShippingMessage,
  getIrApproveShippingCompleted,
  getIrMarkShippedModal,
  getIrMarkShippedPending,
  getRefresh,
  getShippingLabelModal,
  getError,
  getMarkShippedCases,
  getSelectedShipDate,
  getShipDateError,
  getInternationalModal,
} from '../../../redux/reducers/bpp/shipping/shipping';
import { getSingleToothIdbFinalFile, getSingleToothIdbStatus } from '../../../redux/reducers/bpp/single_tooth_idb/single_tooth_idb';

/**
 * Used to display the shipping section in case details page for item request
 * @alis ItemRequestShippingContainer
 * @component
 * @category BPP
 */
class ShippingContainer extends Component {
  /**
   * Determines if the item request is ready to be approved for shipping
   * @function
   * @return {Boolean} True or False
   */
  isReadyForShipping(item_request, isGen2) {
    let item_parts_quantity = [];
    let is_production_wo_present = true;

    this.props.case_details.item_request_details.forEach((item_request_detail) => {
      if (item_request_detail.ireq_id === item_request.ireq_id) {
        if (!item_request_detail.production_wo || item_request_detail.production_wo.length === 0) {
          is_production_wo_present = false;
        }
        item_parts_quantity.push(item_request_detail);
      }
    });

    const ireq_parts = item_parts_quantity.filter((ip) => {
      return ip.description.toLowerCase().includes('smartwire');
    });

    this.props.case_details.item_request_details.forEach((item_request_detail) => {
      if (item_request_detail.ireq_id === item_request.ireq_id) {
        if (!item_request_detail.production_wo || item_request_detail.production_wo.length === 0) {
          is_production_wo_present = false;
        }
      }
    });

    let latest_status = {};
    if (this.props.awb && this.props.awb.ireq_automation_status) {
      latest_status = this.props.awb.ireq_automation_status.filter((status) => {
        return status.current_ind === true;
      })[0];
    }

    const status = latest_status && latest_status.status ? latest_status.status : '';
    //TODO: Block Shipping when not complete
    const is_completed_wire_selection_state = true;

    let production_compete =
      ireq_parts.length === 0 ||
      status === 'IREQ AWB Override Process' ||
      status === 'IREQ AWB Outputs Approved' ||
      status === 'IREQ AWB Rework' ||
      status === 'IREQ AWB Manual Transfer' ||
      status === 'IREQ AWB Manual Rework' ||
      (isGen2 && is_completed_wire_selection_state)
        ? true
        : false;

    const label_generation_incomplete =
      item_request.files.length === 0 && item_request.label_generation.length > 0 && item_request.label_generation[0].status !== 'Label Override Process';

    const has_zero_label_files =
      item_request.files.length === 0 &&
      item_request.label_generation.length > 0 &&
      item_request.label_generation[0].status === 'Label Generation Success' &&
      item_request.files.filter((file) => file.file_type === 'labels').length === 0;

    const has_zero_manual_label_files =
      item_request.label_generation.length > 0 &&
      item_request.label_generation[0].status === 'Label Manual Process' &&
      item_request.files.filter((file) => file.file_type === 'labels_manual').length === 0;

    const has_correct_label_status =
      item_request.label_generation.length > 0 &&
      (item_request.label_generation[0].status === 'Label Generation Success' ||
        item_request.label_generation[0].status === 'Label Override Process' ||
        item_request.label_generation[0].status === 'Label Manual Process');

    const ws_override = this.props.ir_ws_status === 'WS Override';
    const smart_wires = this.props.case_details.item_request_details.filter(
      (detail) => detail.ireq_id === item_request.ireq_id && detail.part_category === 'smartwire'
    );
    const ws_conversion_complete = !this.props.gen_2 || smart_wires.length === 0 || (this.props.ir_ws_status && this.props.ir_ws_status !== 'WS Conversion');
    const has_ws_guide = !this.props.gen_2 || smart_wires.length === 0 || this.props.wire_selection_guide || ws_override;

    const is_single_tooth_idb_completed =
      this.props.single_tooth_idb_status === 'Single Tooth IDB Overridden' ||
      (this.props.single_tooth_idb_status ? this.props.single_tooth_idb_final_file : true);

    return (
      !this.props.ir_label_generation_process &&
      item_request.label_generation.length !== 0 &&
      !label_generation_incomplete &&
      !has_zero_label_files &&
      !has_zero_manual_label_files &&
      has_correct_label_status &&
      is_production_wo_present &&
      !this.props.ir_edit_in_progress &&
      !(!this.props.gen_2 && !production_compete) &&
      ws_conversion_complete &&
      has_ws_guide &&
      (this.isTemplateNoFilledOut() || ws_override) &&
      is_single_tooth_idb_completed
    );
  }

  isTemplateNoFilledOut = () => {
    const templateNo = this.props.templateNo;

    if (templateNo) {
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(templateNo)) {
        if (value === 'N/A') {
          return false;
        }
      }
    }

    return true;
  };

  /**
   * Gets shipping label url if exists in irfile
   * @function
   * @return {String} Shipping label url used for displaying the pdf in modal
   */
  getShippingLabel() {
    if (this.props.item_request.shipping_label_files.length > 0) {
      const shipping_label_file = this.props.item_request.shipping_label_files.find((file) => file.file_type === 'shipping_label');
      return shipping_label_file;
    }
    return null;
  }

  /**
   * Determines if manual shipping fields are filled out for all item requests in the mark shipped modal
   * @function
   * @return {Boolean} True if completed, False otherwise
   */
  isManualShippingCompleted() {
    for (const cases of this.props.mark_shipped_cases) {
      if (cases.shipping_method === 'manual_shipping' && (!cases.tracking_number || !cases.shipping_service)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Gets warning text when trying to mark shipped
   * @function
   * @returns {String} Warning text
   */
  getMarkShipWarningText() {
    const selected_ship_date = this.props.selected_ship_date;
    if (!isValidMarkShippedDate(this.props.mark_shipped_cases, selected_ship_date)) {
      if (!isDateOnOrBeforeToday(selected_ship_date)) {
        return "The entered date should be on or before today's date";
      } else {
        return 'The entered date should be on or after the Item Request approval date';
      }
    }
    return '';
  }

  showApprovalModal = () => {
    const country = this.props?.case_details?.shipping_addresses.find((address) => address.id === this.props.ir_selected_address_id).country;
    if (country === 'United States') {
      this.props.toggleModal('ir_approve_shipping_modal', !this.props.ir_approve_shipping_modal)
    } else {
      this.props.openInternationalModal()
    }
  }

  renderShipping = (item_request) => {
    const isGen2 = this.props.gen_2;
    const shipping_label_file = this.getShippingLabel();

    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <div>
              <div className="row business-dark-theme-content">
                <div className="col-lg-12">
                  <div className="business-dark-group">
                    <div className="dark-filter pull-left shipping-display">
                      <div className="shipping-container">
                        <span className="bold-text ship-title">Preferred Shipping:</span>
                        <span className="expedite-shipping-label">{item_request.preferred_shipping ? item_request.preferred_shipping : 'N/A'}</span>
                      </div>
                    </div>
                    <div className="dark-filter pull-left shipping-display">
                      <div className="shipping-container">
                        <span className="bold-text ship-title">Expedited Shipping:</span>
                        <span className="expedite-shipping-label">{item_request.expedite_ship_ind ? 'Yes' : 'No'}</span>
                      </div>
                    </div>
                    <div className="dark-filter pull-left">
                      <div className="shipping-container">
                        <span
                          className={
                            item_request.ireq_status !== 'Item Request Shipped' && !this.props.item_request.shipping_approved_ind
                              ? 'bold-text ship-title'
                              : 'bold-text shipped-title'
                          }
                        >
                          Shipping Address:
                        </span>

                        <select
                          className="select-address"
                          data-width="392px"
                          data-divider="true"
                          data-style="select-address"
                          value={this.props.edit_ir_selected_address_id ? this.props.edit_ir_selected_address_id : this.props.ir_selected_address_id}
                          onChange={this.props.onSelectedAddress}
                          disabled={
                            item_request.shipping_approved_ind ||
                            item_request.ireq_status === 'Item Request Shipped' ||
                            item_request.ireq_status === 'Item Request On Hold' ||
                            item_request.ireq_status === 'Item Request Cancelled' ||
                            !userHasPermission('IREQ_SHIPPING_DETAILS_UPDATE', user_roles_and_permissions.permissions)
                              ? true
                              : false
                          }
                        >
                          {!isActiveAddressById(this.props.case_details.shipping_addresses, this.props.ir_selected_address_id) ? (
                            <option
                              data-content={'<strong> No Active Address Selected </strong><br/> --------------------------------------------------------'}
                              value={this.props.ir_selected_address_id}
                            >
                              --------------------------------------------------------
                            </option>
                          ) : null}
                          {this.props.case_details.shipping_addresses
                            .filter(function (a) {
                              return a.is_active;
                            })
                            .map((address, address_index) => {
                              return (
                                <option
                                  data-content={'<strong>' + getShippingAddressName(address) + '</strong><br>' + getShippingAddress(address)}
                                  key={address_index}
                                  value={address.id}
                                >
                                  {getShippingAddress(address)}
                                </option>
                              );
                            })}
                        </select>
                        {item_request.ireq_status === 'Item Request Shipped' || item_request.shipping_approved_ind ? (
                          <div className="ship-address-label">
                            <div>{getShippingAddressName(item_request.delivered_address)}</div>
                            <div>{getShippingAddress(item_request.delivered_address)}</div>
                          </div>
                        ) : null}
                        {this.props.edit_address ? (
                          <div className="btn-group-action">
                            <div className="btn btn-light btn-next" onClick={this.props.onAddressConfirm}>
                              <i className="fa fa-check" aria-hidden="true" />
                            </div>
                            <div className="btn btn-light btn-next" onClick={this.props.onAddressDiscard}>
                              <i className="fa fa-times" aria-hidden="true" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    {item_request.ireq_status === 'Item Request Shipped' && item_request.hand_delivery_ind ? (
                      <div className="pull-left">
                        <div className="tracking-container">
                          <span className="bold-text other-title">Delivery Method:</span>
                          <div className="remove-bottom-margin">
                            <div className="ship-date-container">Hand Delivered</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="pull-left">
                          <div className="dark-filter">
                            <div className="tracking-container">
                              <span className="bold-text other-title">Tracking Number:</span>
                              <span>
                                {this.props.item_request.tracking_info ? (
                                  !this.props.item_request.tracking_url ? (
                                    <span>{this.props.item_request.tracking_info}</span>
                                  ) : (
                                    <a
                                      href={'http://' + this.props.item_request.tracking_url.replace('http://', '').replace('https://', '')}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {this.props.item_request.tracking_info}
                                    </a>
                                  )
                                ) : (
                                  <span>N/A</span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="pull-left">
                          <div className="dark-filter">
                            <div className="tracking-container">
                              <span className="bold-text other-title">Shipping Label:</span>
                              {shipping_label_file ? (
                                <span className="underline-text viewable-text" onClick={this.props.openShippingLabelModal}>
                                  Shipping Label <i className="fa fa-file-pdf-o" aria-hidden="true" />
                                </span>
                              ) : (
                                <span>N/A</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    {item_request.ireq_status === 'Item Request Shipped' ? (
                      <>
                        <div className="pull-left">
                          <div className="tracking-container">
                            <span className="bold-text other-title">Shipped Date:</span>
                            <div className="remove-bottom-margin">
                              <div className="ship-date-container">{convertDate(item_request.ship_date)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="pull-left">
                          <div className="tracking-container">
                            <span className="bold-text other-title">Delivered Date:</span>
                            <div className="remove-bottom-margin">
                              <div className="ship-date-container">{item_request.delivered_date ? convertDate(item_request.delivered_date) : 'N/A'}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="row ship-btn-container">
                    {item_request.ireq_status !== 'Item Request Shipped' &&
                    item_request.ireq_status !== 'Item Request On Hold' &&
                    item_request.ireq_status !== 'Item Request Cancelled' ? (
                      !item_request.shipping_approved_ind ? (
                        userHasPermission('IREQ_SHIPPING_APPROVAL', this.props.user_roles_and_permissions.permissions) ? (
                          <button
                            type="button"
                            className="btn btn-light btn-shipped"
                            disabled={!this.isReadyForShipping(item_request, isGen2)}
                            onClick={this.showApprovalModal}
                          >
                            Approve for Shipping
                          </button>
                        ) : (
                          <span className="approval-waiting-message bold-text">Awaiting Shipping Approval...</span>
                        )
                      ) : userHasPermission('CASE_SHIPPING_RELEASE', this.props.user_roles_and_permissions.permissions) ? (
                        <button
                          type="button"
                          className="btn btn-light btn-shipped-2"
                          disabled={!this.isReadyForShipping(item_request)}
                          onClick={() => this.props.openIrMarkShippedModal(this.props.item_request, this.props.case_details)}
                        >
                          Mark Shipped
                        </button>
                      ) : (
                        <span className="approval-waiting-message bold-text">Shipping Process in Progress...</span>
                      )
                    ) : null}
                  </div>
                </div>
              </div>

              {this.props.ir_approve_shipping_modal ? (
                <Modal
                  preset="approve-shipping"
                  header_text={'Approve for Shipping - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
                  message_text={this.props.ir_approve_shipping_message}
                  confirm_btn={!this.props.ir_approve_shipping_completed}
                  confirm_btn_text="Approve"
                  close_btn_text={this.props.ir_approve_shipping_completed ? 'Close' : 'Cancel'}
                  onCloseButtonClick={() => this.props.closeIrApproveShippingModal()}
                  onConfirmButtonClick={() => this.props.onIrApproveShippingButtonClick(this.props.item_request.ireq_id, this.props.reload_information)}
                  theme="bpp"
                  in_progress={this.props.ir_approve_shipping_pending}
                  in_progress_text="Sending data to Shippo..."
                />
              ) : null}
              {this.props.ir_mark_shipped_modal ? (
                <Modal
                  preset="mark-shipped"
                  modal_size_class="modal-lg"
                  modal_body_class="overflow-show center-text"
                  header_text={'Mark Shipped - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
                  confirm_btn={!this.props.mark_shipped_error}
                  confirm_btn_text="Ship"
                  close_btn_text={this.props.mark_shipped_error ? 'Close' : 'Cancel'}
                  close_btn={true}
                  onCloseButtonClick={() => this.props.closeIrMarkShippedModal('single')}
                  onConfirmButtonClick={() => this.props.onIrMarkShippedButtonClick(this.props.item_request.ireq_id, this.props.reload_information)}
                  theme="bpp"
                  in_progress={this.props.ir_mark_shipped_pending}
                  in_progress_text="Marking Item Request as Shipped..."
                  datearea_warning_text={this.getMarkShipWarningText()}
                  ship_date_error={this.props.ship_date_error}
                  confirm_btn_disabled={!this.isManualShippingCompleted()}
                />
              ) : null}
              {this.props.shipping_label_modal ? (
                <Modal
                  preset="pdf_viewer"
                  header_text={'Shipping Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
                  modal_size_class="modal-lg modal-lg-pdf"
                  modal_body_class="modal-pdf"
                  onCloseButtonClick={this.props.closeShippingLabelModal}
                  theme="bpp"
                  pdf_url={shipping_label_file.upload_data}
                  pdf_date={shipping_label_file.created_date}
                  original_filename={getPDFNameTemplate(this.props.item_request.ireq_id, this.props.patient_info, 'Label_Shipping.pdf')}
                />
              ) : null}
              {this.props.refresh ? (
                <Modal
                  preset="action"
                  x_btn={false}
                  header_text="Session Expired"
                  message_text="Sorry, your session has expired. Please refresh."
                  confirm_btn_text="Refresh"
                  onConfirmButtonClick={onReloadPage}
                  theme="bpp"
                />
              ) : null}
              {this.props.international_modal ? (<Modal
                preset="international-shiping"
                modal_size_class="modal-lg"
                modal_body_class="overflow-show center-text"
                header_text={`Item Request: ${this.props.item_request.ireq_id}`}
                modal_body='For international orders please ship through Netsuite.'
                close_btn_text={'Close'}
                close_btn={true}
                onCloseButtonClick={() => this.props.closeInternationalModal()}
                theme="bpp"
              />) : null}
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  render() {
    return (
      <div className={'cad-process'}>
        <React.Fragment>{this.renderShipping(this.props.item_request, this.props.isGen2)}</React.Fragment>
      </div>
    );
  }
}

ShippingContainer.propTypes = {
  ir_approve_shipping_modal: PropTypes.bool.isRequired,
  ir_approve_shipping_pending: PropTypes.bool.isRequired,
  ir_approve_shipping_message: PropTypes.object.isRequired,
  ir_approve_shipping_completed: PropTypes.bool.isRequired,
  ir_mark_shipped_modal: PropTypes.bool.isRequired,
  ir_mark_shipped_pending: PropTypes.bool.isRequired,
  refresh: PropTypes.bool.isRequired,
  shipping_label_modal: PropTypes.bool.isRequired,
  mark_shipped_error: PropTypes.bool.isRequired,
  mark_shipped_cases: PropTypes.array.isRequired,
  selected_ship_date: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    international_modal: getInternationalModal(state),
    ir_approve_shipping_modal: getIrApproveShippingModal(state),
    ir_approve_shipping_pending: getIrApproveShippingPending(state),
    ir_approve_shipping_message: getIrApproveShippingMessage(state),
    ir_approve_shipping_completed: getIrApproveShippingCompleted(state),
    ir_mark_shipped_modal: getIrMarkShippedModal(state),
    ir_mark_shipped_pending: getIrMarkShippedPending(state),
    refresh: getRefresh(state),
    shipping_label_modal: getShippingLabelModal(state),
    mark_shipped_error: getError(state),
    mark_shipped_cases: getMarkShippedCases(state),
    selected_ship_date: getSelectedShipDate(state),
    ship_date_error: getShipDateError(state),
    single_tooth_idb_status: getSingleToothIdbStatus(state),
    single_tooth_idb_final_file: getSingleToothIdbFinalFile(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleModal: toggleModal,
      onIrApproveShippingButtonClick: onIrApproveShippingButtonClick,
      onIrMarkShippedButtonClick: onIrMarkShippedButtonClick,
      closeIrApproveShippingModal: closeIrApproveShippingModal,
      openIrMarkShippedModal: openIrMarkShippedModal,
      closeIrMarkShippedModal: closeIrMarkShippedModal,
      openShippingLabelModal: openShippingLabelModal,
      closeShippingLabelModal: closeShippingLabelModal,
      openInternationalModal: openInternationalModal,
      closeInternationalModal: closeInternationalModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShippingContainer);
